import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 57",
  week: "Semana 9",
  day: "09",
  month: "may",
  monthNumber: "05",
  date: "2020-05-09",
  path: "/cronologia/semana-09#dia-09-may",
};
const Day57 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.666 nuevos positivos y 179 personas fallecidas.
          Permanecen hospitalizadas 122.265 personas, 489 más que el día
          anterior, y 2.804 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.292, lo que supone un aumento de 70 personas.{" "}
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,1 % y la tasa de recuperados del 51,0 %.{" "}
        </ModText>
        <ModImage
          src="https://coronavirus.secuoyas.com/svg/09_abr_une.svg"
          alt="millones de test de coronavirus realizados"
        />
        <ModText>
          España ha superado el umbral de 1,6 millones de pruebas PCR realizadas
          hasta la fecha. Los test realizados, por CCAA, se pueden consultar en
          el siguiente{" "}
          <InlineLink link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/COVID-19_pruebas_diagnosticas_07_05_2020.pdf">
            enlace
          </InlineLink>
          .
        </ModText>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/23_mar_presidente.svg"
          alt="ministro de sanidad"
          small={false}
          inverted={false}
        >
          El ministro de Sanidad, Salvador Illa, ha anunciado las medidas
          relativas a la aplicación de la fase 1 del Plan para la transición
          hacia una nueva normalidad, así como los territorios en los que se
          aplicarán. La{" "}
          <InlineLink link="https://boe.es/boe/dias/2020/05/09/pdfs/BOE-A-2020-4911.pdf">
            Orden
          </InlineLink>
          ha quedado recogida en el Boletín Oficial del Estado.
        </ModTwoCols>
        <ModText>
          Un 51% de los ciudadanos españoles estará a partir del lunes 11 de
          mayo en condiciones de flexibilizar las medidas de distanciamiento
          social, al pasar sus territorios de residencia a la Fase I del Plan de
          desescalada.
        </ModText>
        <ModText>
          En estos territorios, estará permitido el contacto social de hasta un
          máximo de 10 personas, sin contar a los convivientes. Deberán, en todo
          caso, mantener una distancia mínima de seguridad de dos metros o
          establecer medidas de protección física y de higiene de manos.
        </ModText>
        <ModText>
          La orden también permitirá, entre otros, la reapertura de las terrazas
          de hostelería y restauración al aire libre, hasta un número de mesas
          inferior al 50% de las autorizadas. En cada mesa o agrupación de mesas
          podrá haber como máximo 10 personas, que, además, deberán respetar la
          distancia interpersonal.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/covid-19/Documents/09052020_Plan_Transicion_Guia_Fase_1.pdf"
            name="Guía de la Fase 1"
          />

          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Documents/2020/08052020_Fase1.pdf"
            name="Territorios que pasan a la Fase 1"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Documents/2020/08052020_Criterios.pdf"
            name="Cuadro de criterios para el cambio de fase"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day57;
